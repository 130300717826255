<template functional>
  <div class="app-divider">
    <div class="tw-flex tw-items-center tw-justify-between">
      <div
        v-if="props.align !== 'left'"
        :class="{'tw-hidden': props.align === 'left',
                 'tw-w-full': props.align === 'right',
                 '!tw-w-1/2': props.align === 'center',
                 'tw-hidden': $slots.default}"
        class="xs:tw-block app-divider__left">
        <hr class="tw-border-jb-grey-100 tw-border-t-0 tw-border-b">
      </div>
      <div v-if="$slots.default" class="app-divider__content xs:tw-w-auto tw-w-full">
        <slot />
      </div>
      <div
        v-if="props.align !== 'right'"
        :class="{'tw-hidden': props.align === 'right',
                 'tw-w-full': props.align === 'left',
                 '!tw-w-1/2': props.align === 'center',
                 'tw-hidden': $slots.default}"
        class="xs:tw-block app-divider__right">
        <hr class="tw-border-jb-grey-100 tw-border-t-0 tw-border-b">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    align: {
      type: String,
      default: 'right',
      validator: function(value) {
        return ['left', 'right', 'center'].indexOf(value) !== -1;
      }
    }
  }
};
</script>
