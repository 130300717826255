<template>
  <div class="referral-payments sm:tw-mt-16 tw-m-auto tw-mt-0">
    <section v-if="isRouteSetup" class="internal-routes tw-pt-layout-3 tw-max-w-screen-lg tw-mx-auto">
      <app-heading tag="h2" size="h3" weight="semi-bold" color="headings" class="tw-mb-space-4 md:tw-text-h2">
        Referral Payment Setup
      </app-heading>
      <app-divider>
        <app-button size="mini" class="tw-bg-jb-indigo xs:tw-w-48 xs:tw-px-4 tw-w-full" @click="$router.push({name: 'referralManagement'})">
          Complete Setup Later
        </app-button>
      </app-divider>
    </section>
    <AppIframe
      loading-text="Loading Tipalti Payments"
      :src="src"
      :load-delay="1500"
      :style="{ height: iframeHeight }"
      :class="{['tw-min-h-0']: completedSetup || historyLoaded}" />
    <section v-if="historyLoaded" class="internal-routes tw-py-layout-4 tw-max-w-screen-lg tw-mx-auto">
      <app-divider align="right">
        <app-button size="mini" class="tw-bg-jb-indigo xs:tw-w-64 xs:tw-px-4 tw-w-full" @click="$router.push({name: 'referralManagement'})">
          Back to Referral Management
        </app-button>
      </app-divider>
    </section>
    <section v-if="completedSetup" class="internal-routes tw-pb-layout-4 tw-max-w-screen-lg tw-mx-auto">
      <app-divider>
        <app-button size="mini" class="tw-bg-jb-indigo xs:tw-w-48 xs:tw-px-4 tw-w-full" @click="$router.push({name: 'referralManagement'})">
          Proceed to Referrals
        </app-button>
      </app-divider>
    </section>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppIframe from '@/components/AppIframe.vue';
import AppHeading from '@/components/AppHeading.vue';

export default {
  name: 'Payments',
  components: { AppButton, AppDivider, AppIframe, AppHeading },
  props: {
    endpoint: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      iframeHeight: undefined,
      completedSetup: false
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (to.name === 'referralMarketingLayout' && !this.isSetupAlreadyComplete) {
      await this.$confirm(
        'You are about to leave without completing setup. Are you sure?', 'Confirmation required',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
          customClass: 'payments-confirm'
        }
      );

      return next(false);
    }
    next();
  },
  computed: {
    orgId() {
      return this.$store.getters.organization.id;
    },
    userId() {
      return this.$store.getters.user.user_id;
    },
    sessionId() {
      return this.$store.getters.user.session_id;
    },
    isRouteSetup() {
      return this.$route.name === 'referral-setup';
    },
    src() {
      const domain = process.env.VUE_APP_PLATFORM_API_URL;
      const path = `/v2/user/${this.userId}${this.endpoint}?api-token=${this.sessionId}`;

      return domain + path;
    },
    isSetupAlreadyComplete() {
      return this.$store.getters.isSetupComplete;
    },
    historyLoaded() {
      return this.endpoint.includes('history') && this.iframeHeight > '300px';
    }
  },
  created() {
    window.addEventListener('message', this.handleMessage, false);
  },
  destroyed() {
    window.removeEventListener('message', this.handleMessage, false);
  },
  methods: {
    handleMessage({ data }) {
      if (data && data.TipaltiIframeInfo) {
        this.iframeHeight = parseInt(data.TipaltiIframeInfo.height, 10) + 48 + 'px';

        if (this.iframeHeight >= '450px' && this.iframeHeight <= '650px') {
          this.completedSetup = true;
          this.$store.dispatch('setupComplete', true);
        } else {
          this.completedSetup = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.referral-payments {
  @apply
    tw-bg-white tw-rounded
    tw-pt-layout-1 tw-pr-space-4 tw-pb-0 tw-pl-space-4
    tw-max-w-screen-app-content-width
    sm:tw-pt-layout-1 sm:tw-px-layout-4
    md:tw-pt-layout-3 md:tw-px-space-4 md:tw-mt-layout-3 md:tw-mx-space-4 md:tw-mb-0
    lg:tw-pt-layout-1 lg:tw-px-layout-4 lg:tw-mt-layout-5;
}

.payments-confirm {
  .el-message-box {
    &__title {
      @apply tw-text-headings tw-font-semibold;
    }

    &__status.el-icon-warning {
      @apply tw-hidden;
    }

    &__btns .el-button--primary {
      @apply tw-bg-jb-indigo;
    }

    &__btns .el-button--small {
      &:hover {
        @apply tw-border-jb-indigo;
      }
    }

    &__status + .el-message-box__message {
      @apply tw-pl-0;
    }

    &__message span {
      @apply tw-text-jb-grey-700 tw-font-medium;
    }

    &__headerbtn {
      @apply tw-hidden;
    }
  }
}
</style>
